import React, {useState} from 'react'
import {AnimatePresence, motion} from 'framer-motion'
import {wrap} from 'popmotion'
import Section from '../shared/Section'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import styled from 'styled-components'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/Slideshow/presets'


// TODO: Do we need domain and category fields

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 520px;

  .next,
  .prev {
    top: calc(50% - 20px);
    position: absolute;
    background: white;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    z-index: 2;
  }

  .next {
    right: 10px;
  }

  .prev {
    left: 10px;
    transform: scale(-1);
  }

  img {
    position: absolute;
    max-width: 100%;
  }

  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`

export default function Slideshow(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    images,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  const [[page, direction], setPage] = useState([0, 0])
  const [inView, setInView] = useState('visible')
  const imageIndex = wrap(0, images?.items?.length, page)
  const [imageCaption, setImageCaption] = useState(images?.items?.[imageIndex]?.caption ?? '')


  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection])

  }

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className}
      name="slideshow"
      section_background={section_background}
    >
      <div className="section-container ">
        <div className="section-content">
          <div className="section-heading">
            <span className="title color-scheme-1-title heading-1">
              <RichtextWithVariables richtext={title} cms={cms} name={'title'}/>
            </span>
              <span className="subtitle color-scheme-1-subtitle heading-2">
              <RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/>
            </span>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
                </div>
              </div>
            </div>
          </div>

          <div className="slideshow">
            <StyledDiv>
              <AnimatePresence initial={false} custom={direction}>
                <motion.img
                    key={page}
                    src={images?.items?.[imageIndex].src}
                    custom={direction}
                    variants={variants}
                    initial='enter'
                    animate='center'
                    exit='exit'
                    transition={{
                      x: {type: 'spring', stiffness: 300, damping: 30},
                      opacity: {duration: 0.2},
                    }}
                    onAnimationStart={() => {
                      setInView('hidden')
                    }}
                    onAnimationComplete={() => {
                      setInView('visible')
                      setImageCaption(images?.items?.[imageIndex].caption)
                    }}
                    drag='x'
                    dragConstraints={{left: 0, right: 0}}
                    dragElastic={1}
                />
              </AnimatePresence>

              <div className='next' onClick={() => paginate(1)}>
                {'‣'}
              </div>
              <div className='prev' onClick={() => paginate(-1)}>
                {'‣'}
              </div>
            </StyledDiv>
            <div style={{
              textAlign: 'center',
              marginBottom: '2em',
            }}>
              <motion.div
                  initial="hidden"
                  animate={inView}
                  onAnimationComplete={() => {
                    setImageCaption(images?.items?.[imageIndex].caption)
                  }}
                  variants={{
                    hidden: {
                      opacity: 0,
                      y: '0.25em',
                    },
                    visible: {
                      opacity: 1,
                      y: '0em',
                      transition: {type: 'spring', stiffness: 100},
                    },
                  }}
              >
                {
                  imageCaption
                }
              </motion.div>
            </div>
          </div>

          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext paragraph">
                <RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/>
              </div>
            </div>
          </div>

          <div className="button-container">
            <SectionButton cms={cms} href={button?.url} name={'button'} label={button?.label}/>
          </div>
        </div>
      </div>
    </Section>
  )
}

Slideshow.StyleCore = styleCore
Slideshow.presets = presets


