import LucidImage from '@/src/utils/LucidImage'
import React, {CSSProperties, useEffect, useState} from 'react'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import styled from 'styled-components'
import useWindowDimensions, {windowSize, WindowSizeKey} from './useWindowWidthListener'
import type {Media} from '@einsteinindustries/tinacms-core'
import isSvg from 'is-svg'

interface SectionImageProps extends Omit<ImageWrapperProps, 'imageSize'> {
  name: string
  cms: boolean
  width: number
  height: number
  style?: CSSProperties
}

interface ImageWrapperProps {
  src: string
  icon: string
  mobile?: {
    src?: string
    transitionPoint?: string
  }
  alt: string
  link?: string
  imageSize: ImageSize | null
  className?: string
  priority: boolean | string
  sizes: string
}

interface ImageSize {
  width: number | string
  height: number | string
}

/**
 * Some other bug with Next.js.
 * Images with sizes specified to them will set a width and height of 0
 */
const ProgressiveImageFix = styled.div`
  span, div {
    position: unset!important;
    padding-top: unset!important;
  }
  img.progressive-image {
    width: 100%!important;
    height: unset!important;
    position: relative!important;
  }
`

export function generateSizesAttribute(maxWidthIndex: number) {
  const maxSize = windowSize[maxWidthIndex]
  return `(min-width: ${maxSize}px) ${maxSize}px, 100vw`
}

export default function SectionImage({
  name,
  src,
  mobile,
  icon,
  alt,
  width,
  height,
  link,
  cms,
  className,
  style,
  priority,
  sizes
}: SectionImageProps) {

  const [inlineImageComponent, setInlineImageComponent] = useState<JSX.Element | null>(null)

  useEffect(() => {
    const setInlineImageModule = async () => {
      const {InlineImage} = await import('@einsteinindustries/react-tinacms-inline')
      setInlineImageComponent(
        <InlineImage
          name={`${name}.src`}
          parse={(media: Media) => {
            if (!media) {
              return ''
            }
            return constructAssetUrl(media.id)
          }}
          previewSrc={(src: string) => src}
          focusRing={true}
          alt={alt}
          className={className}
        >
          {({src: loaded}: {src?: string}) => {
            return loaded ? (
              <ImageWrapper
                src={src}
                icon={icon}
                mobile={mobile}
                alt={alt}
                imageSize={{width, height}}
                className={className}
                priority={priority}
                sizes={sizes}
              />
            ) : null
          }}
        </InlineImage>
      )
    }
    if (cms && !icon) {
      setInlineImageModule()
    }
    if (cms && icon) {
      setInlineImageComponent(<ImageWrapper
        src={src}
        icon={icon}
        mobile={mobile}
        alt={alt}
        imageSize={{width, height}}
        className={className}
        priority={priority}
        sizes={sizes}
      />)
    }
  }, [src, icon, alt, width, height, className, priority, sizes])

  return (
    <ProgressiveImageFix style={style} className={className}>
      {cms ? inlineImageComponent :
        <ImageWrapper
          src={src}
          icon={icon}
          mobile={mobile}
          alt={alt}
          link={link}
          imageSize={{width, height}}
          className={className}
          priority={priority}
          sizes={sizes}
        />
      }
    </ProgressiveImageFix>
  )
}

function adjustSVGDimensions(svg:string, width?: number, height?: number) {
  if (svg.includes('width=') && svg.includes('height=')) {
    return svg
  }

  if (!width || !height) {
    return svg
  }

  return svg.replace('<svg', `<svg width="${width}" height="${height}"`)
}

function ImageWrapper({src, mobile, icon, alt, imageSize, link, priority, className, sizes}: ImageWrapperProps) {
  const {size} = useWindowDimensions()

  const transitionKey = (mobile?.transitionPoint ?? 'md') as keyof typeof WindowSizeKey
  const isMobile = mobile?.src && size <= (WindowSizeKey[transitionKey])

  const responsiveSizes = isMobile ? generateSizesAttribute(WindowSizeKey[transitionKey]) : sizes
  const displaySrc = isMobile ? mobile.src : src

  if (displaySrc || icon) {
    let imgComponent
    if (!icon && displaySrc) {
      imgComponent = (
        <LucidImage
          src={displaySrc}
          alt={alt}
          width={imageSize?.width}
          height={imageSize?.height}
          className={className}
          priority={priority === 'true' || priority === true}
          sizes={responsiveSizes}
        />
      )
    } else {
      const isValidSVG = isSvg(icon)
      if (icon.includes('<script')) {
        imgComponent = <p>Dangerous SVG - Contains scripts. Please remove the script.</p>
      } else {
        imgComponent = !isValidSVG ? <p>Invalid SVG</p> : <span className="color-scheme-svg" dangerouslySetInnerHTML={{__html: adjustSVGDimensions(icon, Number(imageSize?.height), Number(imageSize?.width))}} />
      }
    }
    return link && link !== '' ? <a href={link}>{imgComponent}</a> : imgComponent
  }
  return null
}
