import {LucidBlocksControls} from '../shared/LucidInlineGroups'
import {IMAGE_FIELDS} from '../shared/InputTypesFields'
import Link from 'next/link'

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

const itemTemplates = {
  menu_block: {
    ...baseItem,
    label: 'Menu Block Item',
    defaultItem: {
      ...baseItem.defaultItem,
      title: 'Title',
      image: {
        src: '',
        alt: '',
      },
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: IMAGE_FIELDS,
      },
      {
        name: 'href',
        label: 'Link',
        component: 'text',
      },
    ],
  },
}

export const MENU_BLOCK_ITEM_BLOCKS = {
  menu_block: {
    Component({_name, index, data, cms}) {
      const innerContent = <ItemWrapper index={index} cms={cms}>
        <div className="menu-block-item-aspect-ratio">
          <div className="menu-block-item-container menu-block-item-label">
            <span>
              <span className="menu-item-title menu-block-item-title">{data.title}</span>
            </span>
          </div>
          <div className="menu-block-item-image" style={{
            backgroundPosition: '50% 50%',
            backgroundImage: `url(${data.image.src})`,
          }}></div>
          <div className="dimmer" style={{
            opacity: 0.2,
          }}></div>
        </div>
      </ItemWrapper>
      if (!data.href) {
        return <div className="menu-block-item">
          {innerContent}
        </div>
      } else {
        return <Link href={data.href} className="menu-block-item">
          {innerContent}
        </Link>
      }
    },
    template: itemTemplates.menu_block,
  },
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      index={props.index}
      focusRing={{offset: -9, borderRadius: 4}}
      insetControls={false}
      cms={props.cms}
    >
      {props.children}
    </LucidBlocksControls>
  )
}
