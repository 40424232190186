import Section from '../shared/Section'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import {AFFILIATIONS_ITEM_BLOCKS} from './config'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import {presets} from './presets'
import {StyleCore} from '@/components/sections/Affiliations/stylecore'

export default function Affiliations(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    className,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      className={className + ' section affiliations-section'}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      name="affiliations"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <span className="title heading-1">
              <RichtextWithVariables richtext={title} cms={cms} name={'title'}/>
            </span>
            <span className="subtitle heading-2">
              <RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/>
            </span>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
                </div>
              </div>
            </div>
          </div>
          <div className="section-widget">
            <div className="row expanded">
              <div className="column">
                <div className="affiliations affiliations_gutter_column_1 affiliations_gutter_row_1">
                  <LucidInlineBlocks
                    name="affiliations_items"
                    blocks={AFFILIATIONS_ITEM_BLOCKS}
                    itemProps={props}
                    className="row-section-images"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext paragraph">
                <RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

Affiliations.StyleCore = StyleCore
Affiliations.presets = presets
