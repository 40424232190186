import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {MENU_PHOTO_CARD_ITEM_BLOCKS} from './config'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionButton from '../shared/SectionButton'
import {presets} from './presets'
import {styleCore} from './stylecore'


export default function MenuPhotoCard(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section menu-photo-card-section menu-section'}
      name="menuphotocard"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h2 style={{textAlign: 'center'}}>
              <span className="title color-scheme-title heading-1">
                <InlineTextWithVariables cms={cms} name="title" value={title}/>
              </span>
              <span className="subtitle color-scheme-subtitle heading-2">
                <InlineTextWithVariables cms={cms} name="subtitle" value={subtitle}/>
              </span>
            </h2>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables
                    name="intro"
                    richtext={intro}
                    cms={cms}
                  />
                </div>
              </div>
            </div>
          </div>
          <LucidInlineBlocks
            itemProps={props}
            name="menu_photo_card_items"
            blocks={MENU_PHOTO_CARD_ITEM_BLOCKS}
            direction="horizontal"
            className={`row menus menu-photo-card-items item-count-${props?.menu_photo_card_items?.length ?? 0}`}
          />
        </div>
        <div className="section-outro">
          <div className="row">
            <div className="column section-richtext paragraph">
              <RichtextWithVariables
                name="outro"
                richtext={outro}
                cms={cms}
              />
            </div>
          </div>
          <SectionButton name='button' href={button?.url} label={button?.label} cms={cms}/>
        </div>
      </div>
    </Section>
  )
}

MenuPhotoCard.StyleCore = styleCore
MenuPhotoCard.presets = presets
