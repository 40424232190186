import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {TWO_COLUMN_NARROW_WIDE_LIST_IMAGES_ITEM_BLOCKS} from './config'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import SectionImage from '../shared/SectionImage'
import SectionButton from '../shared/SectionButton'
import Section from '../shared/Section'
import {styleCore} from './stylecore'

export default function TwoColumnNarrowWideListImages(props) {
  const {
    title,
    subtitle,
    image,
    richtext,
    button_1,
    intro_richtext,
    outro_richtext,
    button_2,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={
        className + ' section two-column-narrow-wide-list-images-section stack'
      }
      name="twocolumnnarrowwidelistimages"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="row collapse">
            <div className="column column-narrow">
              {image.src && (
                <SectionImage
                  name="image"
                  className={'two-column-narrow-wide-list-images-image'}
                  src={image.src}
                  alt={image?.alt}
                  icon={image?.icon}
                  cms={cms}
                  width={image?.width}
                  height={image?.height}
                  priority={image?.priority}
                  sizes={image?.sizes}
                />
              )}
              <div className="section-richtext">
                <div className="section-heading">
                  <h2>
                    {title && (
                      <span className="title heading-1">
                        <InlineTextWithVariables
                          name="title"
                          value={title}
                          cms={cms}
                        />
                      </span>
                    )}
                    {subtitle && (
                      <span className="subtitle heading-2">
                        <InlineTextWithVariables
                          name="subtitle"
                          value={subtitle}
                          cms={cms}
                        />
                      </span>
                    )}
                  </h2>
                </div>
                {richtext && (
                  <div className="richtext paragraph">
                    <RichtextWithVariables
                      richtext={richtext}
                      cms={cms}
                      name="richtext"
                    />
                  </div>
                )}

                {button_1?.label && button_1?.url && (
                  <div className="section-button-row">
                    <SectionButton
                      href={button_1.url}
                      label={button_1.label}
                      cms={cms}
                      name="button_1"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="column column-wide">
              <div className="section-richtext">
                {intro_richtext && (
                  <div className="intro paragraph">
                    <RichtextWithVariables
                      richtext={intro_richtext}
                      cms={cms}
                      name="intro_richtext"
                    />
                  </div>
                )}

                <LucidInlineBlocks
                  itemProps={props}
                  name="two_column_narrow_wide_items"
                  blocks={TWO_COLUMN_NARROW_WIDE_LIST_IMAGES_ITEM_BLOCKS}
                  className="items"
                />

                {outro_richtext && (
                  <div className="outro paragraph">
                    <RichtextWithVariables
                      richtext={outro_richtext}
                      cms={cms}
                      name="outro_richtext"
                    />
                  </div>
                )}

                {button_2?.label && button_2?.url && (
                  <div className="section-button-row button">
                    <SectionButton
                      href={button_2.url}
                      label={button_2.label}
                      cms={cms}
                      name="button_2"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

TwoColumnNarrowWideListImages.StyleCore = styleCore
