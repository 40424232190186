import React, {useState, useEffect, useContext} from 'react'
import RSSReader, {RSSItem} from '../shared/RSSReader'
import {Checkbox, Grid, Input, Spacer, Text} from '@nextui-org/react'
import Helpers from '@/src/utils/shared/helpers'
import Loading from '../../managers/MenuManager/LoadingSpinner'
import useSWR from 'swr'
import {GET_SITE_BUILD_PLUGINS_BY_TYPE} from '@/src/graphql/queries'
import {lucidDataFetcherV2} from '@/src/graphql/fetchers'
import {GraphQlFetcher} from '../../shared/types'
import {LucidSiteContext} from '@/src/state/site/Store'
import {Plugin} from '@/src/components/shared/types'

type BlogPostPickerProps = {
  setSelectedPosts: React.Dispatch<React.SetStateAction<RSSItem[]>>
}

const BlogPostPicker = ({setSelectedPosts}: BlogPostPickerProps) => {
  const [{site, site_build_id, revalidateOnFocus}] = useContext(LucidSiteContext)
  const [blogPosts, setBlogPosts] = useState<RSSItem[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedPosts, setSelectedPostsState] = useState<RSSItem[]>([])
  const [order, setOrder] = useState<{[key: string]: number}>({})
  const [searchQuery, setSearchQuery] = useState('')
  const [selectAllChecked, setSelectAllChecked] = useState(false)

  const domain = site?.domain ?? ''

  const filteredBlogPosts = blogPosts.filter(post => post.title.toLowerCase().includes(searchQuery.toLowerCase()))

  const {
    data: pluginsData,
    isValidating: pluginsLoading,
  } = useSWR<{data: {pluginsByType: Plugin[]}}>(
    [GET_SITE_BUILD_PLUGINS_BY_TYPE, {site_build_id, plugin_type: 'BLOG'}],
    lucidDataFetcherV2 as GraphQlFetcher<'pluginsByType', Plugin[]>,
    {revalidateOnFocus}
  )

  const plugins = pluginsData?.data.pluginsByType ?? []
  const feedPath = plugins.find(plugin => plugin.active)?.route.url
  const feed = feedPath ? `https://${domain.split('.').slice(0, -1).join('.')}.einsteinapps.com${feedPath}/index.rss` : null

  const handleSelectPost = (post: RSSItem) => {
    setSelectedPostsState(prev => {
      const updatedSelectedPosts = prev.find(p => p.guid === post.guid)
        ? prev.filter(p => p.guid !== post.guid)
        : [...prev, post]

      const updatedOrder: {[key: string]: number} = {...order}
      updatedSelectedPosts.forEach((p, index) => {
        updatedOrder[p.guid] = index + 1
      })
      setOrder(updatedOrder)

      return updatedSelectedPosts
    })
  }

  const handleSelectAll = (checked: boolean) => {
    setSelectAllChecked(checked)
    if (checked) {
      setSelectedPostsState(filteredBlogPosts)
      const updatedOrder: {[key: string]: number} = {}
      filteredBlogPosts.forEach((post, index) => {
        updatedOrder[post.guid] = index + 1
      })
      setOrder(updatedOrder)
    } else {
      setSelectedPostsState([])
      setOrder({})
    }
  }

  useEffect(() => {
    setSelectAllChecked(selectedPosts.length === filteredBlogPosts.length)
  }, [selectedPosts, filteredBlogPosts])

  useEffect(() => {
    const fetchRSSData = async () => {
      if (!feed) {
        setIsLoading(false)
        return
      }

      try {
        setIsLoading(true)
        const data = await RSSReader(feed)
        const formattedData = data.map((post) => {
          const authorName = post.author?.match(/\(([\x00-\x7F]+)\)/)
          const formattedDate = Helpers.format.human.date.long(post.pubDate)
          const truncatedDescription = Helpers.format.human.count.truncateToWordCount(post.description, 25)
          return {
            ...post,
            description: truncatedDescription,
            date: formattedDate,
            author: authorName?.[1] ? authorName[1] : null
          } as RSSItem
        })
        setBlogPosts(formattedData)
      } catch (error: any) {
        setError(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchRSSData()
  }, [feed])

  useEffect(() => {
    setSelectedPosts(selectedPosts)
  }, [selectedPosts, setSelectedPosts])

  if (!feed) {
    return <div>No Blog Found! Please enable the Blog plugin.</div>
  }

  if (isLoading || pluginsLoading) {
    return <Loading />
  }

  if (!blogPosts.length) {
    return <div>No posts found!</div>
  }

  if (error) {
    console.error(error)
    return <div>Error loading posts</div>
  }

  return (
    <>
      <Input clearable underlined placeholder="Search posts" onChange={(e) => setSearchQuery(e.target.value)} />
      <Checkbox isSelected={selectAllChecked} onChange={(e) => handleSelectAll(e)}>Select All</Checkbox>
      <Grid.Container gap={1}>
        {filteredBlogPosts.map(post => (
          <Grid xs={12} key={post.link}>
            <Checkbox 
              isSelected={selectedPosts.some(selectedPost => selectedPost.guid === post.guid)}
              onChange={() => handleSelectPost(post)}
            >
              <Text>{post.title}</Text>
            </Checkbox>
            <Spacer x={0.5} />
            {selectedPosts.some(selectedPost => selectedPost.guid === post.guid) && (
              <Text color="primary">
                {order[post.guid]}
              </Text>
            )}
          </Grid>
        ))}
      </Grid.Container>
    </>
  )
}

export default BlogPostPicker
