import type {TypographyElement} from '@/components/editor/typography/types'
import type {StyleCoreTarget, TinaFunctionalComponent} from '@/components/shared/StyleCore'

const SITE_DEFAULT_FORM_ID = 'site-default'

export const StyleCoreHelpers = {
  selectors: {
    get: (target: StyleCoreTarget) => {
      const targetClasses: string[] = [target.componentName]
      if (typeof target.identifier !== 'undefined') {
        targetClasses.push(`ST${target.identifier}`)
      }
      return targetClasses
    },
    getSelectorID: (target: StyleCoreTarget) => {
      return StyleCoreHelpers.selectors.get(target).join('-')
    },
    getTypographyBaseSelector: (target: TypographyElement) => {
      const normalizedName = target.name.replaceAll(' ', '-').toLowerCase()
      let additionalSelectors = target.selectors ? `${target.selectors.trimEnd()}` : ''
      if (target.selectors) {
        additionalSelectors = additionalSelectors.endsWith(',') ? additionalSelectors.slice(0, -1) : additionalSelectors
        additionalSelectors = additionalSelectors.split(',').map((selector) => {
          return `{target} ${selector.trim()}`
        }).join(', ')
      }
      if (additionalSelectors === '' && typographyClassToElementMap.has(normalizedName)) {
        return `{target} ${typographyClassToElementMap.get(normalizedName) as string}`
      }
      /**
       * To follow proper semantics, we don't want to target classes, we want to target actual html tags
       * This won't have any effect if the name they pick is a random name since we're treating the name as a tag
       * 
       * They'll have to either name the element according to an actual HTML tag, or create a custom selector
       */
      return additionalSelectors !== '' ? additionalSelectors : `{target} ${normalizedName}`
    }
  },
  formManager: {
    convertTargetToFormID: (target: StyleCoreTarget) => {
      const targetClasses = StyleCoreHelpers.selectors.get(target)
      return targetClasses.join('::')
    },
    convertFormIDToTarget: (formID: string) => {
      const targetClasses = formID.split('::')
      return {
        componentName: targetClasses[0],
        identifier: targetClasses[1]
      }
    }
  }
}

/**
 * TODO: I continued building this and then we had those calls with the designers
 *    I'm not relying on this with typography migrations so this can be deleted
 *    if it is decide to not autogenerate them and instead pick from dropdown.
 */
export const typographyClassToElementMap = new Map<string, string>([
  ['link', 'a'],
  ['list', 'ul'],
  ['list-item', 'li'],
  ['heading-1', 'h1'],
  ['heading-2', 'h2'],
  ['heading-3', 'h3'],
  ['heading-4', 'h4'],
  ['heading-5', 'h5'],
  ['heading-6', 'h6'],
  ['citation', 'cite'],
  ['title', '.title h1'],
  ['button-text', 'button'],
  ['list-items', 'li, li a'],
  ['subtitle', '.subtitle h2'],
  ['paragraph', 'p, .paragraph'],
  ['definition-list-term', 'dt, dt a'],
  ['definition-list-description', 'dd, dd a'],
  ['paragraph-link', 'p a, .paragraph a, .paragraph a'],
  ['navigation-items', '.navigation-inner .link-control a'],
  ['blockquote', 'blockquote, blockquote a, blockquote p, .quote'],
  ['section_description', '.section-richtext p, .section-outro p'],
  ['description', '.section-richtext p, .section-outro p, .description'],
  ['blockquote-citation', 'blockquote cite, blockquote cite a, blockquote cite p, .quote cite'],
])
