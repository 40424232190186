import type {Media} from '@einsteinindustries/tinacms'
import type {FieldState, FormApi} from 'final-form'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {Button, Checkbox} from '@nextui-org/react'
import {BlocksFieldDefinititon} from '@einsteinindustries/tinacms-fields/build/plugins/BlocksFieldPlugin'
import CustomTinaFieldComponentStyles from '@/src/utils/shared/CustomTinaFieldComponentStyles'
import React from 'react'
import styled from 'styled-components'
import {WindowSizeKey, windowSize} from './useWindowWidthListener'

type ImageOptions = {
  [key in typeof IMAGE_FIELDS[number]['name']]: string | undefined
}

const AutoButtons = styled(Button)`
  margin-bottom: 20px;
`

type ImageWithSrc = FieldState<ImageOptions> & {value: ImageOptions & {src: string}}

function imageNotInitialized(image: FieldState<ImageOptions>): image is ImageWithSrc {
  return typeof image?.value !== 'undefined' &&
    typeof image?.value?.src !== 'undefined' && image?.value.src !== '' &&
    typeof image?.value.width === 'undefined' &&
    typeof image?.value.height === 'undefined' &&
    typeof image?.value.sizes === 'undefined'
}

function resetOriginalDimensions(image: ImageWithSrc) {
  const img = new Image()
  img.onload = function handleOnload(this: any) {
    image.change({...image?.value, width: this.width, height: this.height})
  }
  img.src = image?.value.src
}

function setSizesFromCurrentWidth(image: ImageWithSrc) {
  if (image?.value?.width && image?.value?.width !== '') {
    const sizes = `(max-width: ${image?.value?.width}px) 100vw, ${image?.value?.width}px`
    image.change({...image?.value, sizes})
  }
}

export const BUTTON_FIELDS = [
  {
    label: 'Label',
    name: 'label',
    component: 'text',
  },
  {
    label: 'Link URL',
    name: 'url',
    component: 'text',
  },
]

export const IMAGE_FIELDS = [
  {
    name: 'src',
    label: 'Image',
    component: 'image',
    description: 'Pick Image from the Assets Manager',
    clearable: true,
    parse: (media: Media) => {
      if (!media) {
        return
      }
      return constructAssetUrl(media.id)
    },
    previewSrc: (src: string) => {
      return src
    },
  },
  {
    name: 'src',
    key: 'src.external',
    label: 'External Image',
    component: 'text',
    description: 'Or enter an external Image URL',
  },
  {
    name: 'icon',
    label: 'SVG Icon',
    component: 'text',
    description: 'This will override the image if set',
  },
  {
    name: 'mobile',
    label: 'Mobile Image Options',
    component: 'group',
    fields: [
      {
        name: 'src',
        label: 'Mobile Image',
        component: 'image',
        description: 'Pick an Image from the Assets Manager for mobile devices',
        clearable: true,
        parse: (media: Media) => {
          if (!media) {
            return
          }
          return constructAssetUrl(media.id)
        },
        previewSrc: (src: string) => {
          return src
        },
      },
      {
        name: 'src',
        key: 'src.external',
        label: 'External Mobile Image',
        component: 'text',
        description: 'Enter an external URL for the mobile image',
      },
      {
        name: 'transitionPoint',
        label: 'Transition Point to Mobile Image',
        component: 'select',
        description: 'Select the viewport width at which the site should switch to the mobile image. Default is 768px',
        options: Object.entries(WindowSizeKey).map(([key, index]) => ({
          label: `${key.toUpperCase()}: ${windowSize[index]}px`,
          value: key,
        })),
        defaultValue: 'md',
      }
    ]
  },
  {
    label: 'Alt Text',
    name: 'alt',
    component: 'text',
  },
  {
    label: 'Height',
    name: 'height',
    component: 'number',
    description: 'Maximum possible height'
  },
  {
    label: 'Width',
    name: 'width',
    component: 'number',
    description: 'Maximum possible width'
  },
  {
    label: 'Sizes',
    name: 'sizes',
    description: 'This tells the browser what size you expect the image to be displayed under different media screens/devices',
    component: ({field, input, form}: {field: BlocksFieldDefinititon, input: any, form: FormApi}) => {

      const parts = input.name.split('.')
      const allFields = parts.slice(0, parts.length - 1).join('.')
      const image = form.getFieldState(allFields) as FieldState<ImageOptions>
      if (imageNotInitialized(image)) {
        const img = new Image()
        img.onload = function handleOnload(this: any) {
          const defaultSizes = `(max-width: ${this.width}px) 100vw, ${this.width}px`
          image.change({...image?.value, width: this.width, height: this.height, sizes: defaultSizes})
        }
        img.src = image?.value.src
      }

      return (
        <CustomTinaFieldComponentStyles>
          <AutoButtons
            color="primary"
            bordered
            disabled={typeof image?.value?.src === 'undefined' || image?.value?.src === ''}
            onPress={() => {resetOriginalDimensions(image as ImageWithSrc)}}
          >
            Set original width and height
          </AutoButtons>
          <label>
            {field.label}
            <span>{field.description}</span>
            <span>
              Read more:&ensp;
              <a href='https://web.dev/learn/design/responsive-images/#sizes' target='_blank' rel="noreferrer">here</a>
              &ensp;and&ensp;
              <a href='https://nextjs.org/docs/pages/api-reference/components/image#sizes' target='_blank' rel="noreferrer">here</a>
            </span>
          </label>
          <input
            style={{display: 'block'}}
            onChange={input.onChange}
            type='text'
            name={field.name}
            value={input.value}
          />
          <AutoButtons
            color="primary"
            bordered
            disabled={typeof image?.value?.src === 'undefined' || image?.value?.src === ''}
            onPress={() => {setSizesFromCurrentWidth(image as ImageWithSrc)}}
          >
            Set sizes from current width value
          </AutoButtons>

        </CustomTinaFieldComponentStyles>
      )
    }
  },
  {
    label: 'Priority',
    name: 'priority',
    description: 'Priority Image (Set to true for LCP that is above the fold)',
    /**
     * NOTE:
     *  There's an issue with toggle within a group field
     *  It does not properly show toggle state
     *  This custom component addresses that issue
     */
    component: ({field, input}: {field: BlocksFieldDefinititon, input: any}) => {
      return (
        <CustomTinaFieldComponentStyles>
          <Checkbox
            isRounded
            defaultSelected={input.value === 'true' || input.value === true}
            onChange={input.onChange}
          >
            {field.label}
          </Checkbox>
          <span className='custom-description'>{field.description}</span>
        </CustomTinaFieldComponentStyles>
      )
    }
  },
  {
    label: 'Link',
    name: 'link',
    component: 'text',
    description: 'Optional Link'
  },
  {
    label: 'Caption',
    name: 'caption',
    component: 'text',
    description: 'Optional Only works on certain sections'
  },
]

export const IMAGE_GROUP = [{
  name: 'items',
  label: 'Add Images',
  component: 'blocks',
  templates: {
    image_item: {
      label: 'Image Item',
      fields: IMAGE_FIELDS
    }
  },
}]

export const QUOTE_GROUP = [{
  name: 'items',
  label: 'Add Quotes',
  component: 'blocks',
  templates: {
    quote_item: {
      label: 'Quote Item',
      fields: [
        {
          label: 'Quote',
          name: 'quote',
          component: 'html',
        }
      ]
    }
  }
}]

export const VIDEO_FIELDS = [
  {
    label: 'Title',
    name: 'title',
    component: 'text',
  },
  {
    label: 'Video Source',
    name: 'src',
    component: 'text',
  },
  {
    label: 'Poster Image Source',
    name: 'poster',
    component: 'text',
  },
  {
    label: 'Caption',
    name: 'caption',
    component: 'text',
  },
  {
    label: 'Provider',
    name: 'provider',
    component: 'select',
    options: [
      {label: 'URL', value: 'html5'},
      {label: 'YouTube', value: 'youtube'},
      {label: 'Vimeo', value: 'vimeo'}
    ]
  },
  {
    label: 'Size (1080, 720, 480, etc)',
    name: 'size',
    component: 'number',
  },
]
