import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import Section from '../shared/Section'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore.ts'

export default function Image(props) {
  const {
    title,
    subtitle,
    intro,
    image,
    caption,
    outro,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      name="image"
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section image-section stack'}
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h2>
              <span className="title heading-1">
                <InlineTextWithVariables name="title" value={title} cms={cms} />
              </span>
              <span className="subtitle heading-2">
                <InlineTextWithVariables name="subtitle" value={subtitle} cms={cms} />
              </span>
          </h2>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext">
                  <RichtextWithVariables name="intro" richtext={intro} cms={cms} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column media">
              <figure>
                <div>
                  <SectionImage
                    name='image'
                    src={image?.src}
                    mobile={image?.mobile}
                    alt={image?.alt}
                    icon={image?.icon}
                    width={image?.width}
                    height={image?.height}
                    priority={image?.priority}
                    sizes={image?.sizes}
                    cms={cms}
                  />
                </div>
                <div className="section-richtext caption">
                  <RichtextWithVariables name="caption" richtext={caption} cms={cms} />
                </div>
              </figure>
              <div className="section-outro">
                <div className="row">
                  <div className="column section-richtext">
                    <RichtextWithVariables name="outro" richtext={outro} cms={cms} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {button && button.url && button.label && (
            <div className="button-container">
              <SectionButton
                href={button.url}
                label={button.label}
                cms={cms}
                name="button"
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

Image.StyleCore = styleCore
