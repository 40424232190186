import {
  InlineTextWithVariables,
  RichtextWithVariables,
} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import SectionButton from '../shared/SectionButton'
import Section from '../shared/Section'
import {styleCore} from './stylecore'

export default function HeroProfessional(props) {
  const {
    title,
    subtitle,
    image,
    richtext_1,
    button_1,
    button_2,
    richtext_2,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section hero-professional-section stack'}
      name="heroprofessional"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="row collapse">
            <div className="column column-image">
              {image?.src && (
                <SectionImage
                  name={'image'}
                  className={'hero-professional-image'}
                  src={image?.src}
                  alt={image?.alt}
                  cms={cms}
                  icon={image?.icon}
                  width={image?.width}
                  height={image?.height}
                  priority={image?.priority}
                  sizes={image?.sizes}
                />
              )}
            </div>
            <div className="column column-content">
              <div className="section-richtext">
                <div className="section-heading">
                  <h1>
                    {title && (
                      <span className="title heading-1">
                        <InlineTextWithVariables
                          name="title"
                          value={title}
                          cms={cms}
                        />
                      </span>
                    )}
                    {subtitle && (
                      <span className="subtitle heading-2">
                        <InlineTextWithVariables
                          name="subtitle"
                          value={subtitle}
                          cms={cms}
                        />
                      </span>
                    )}
                  </h1>
                </div>
                {richtext_1 && (
                  <div className="richtext-1 paragraph description">
                    <RichtextWithVariables
                      richtext={richtext_1}
                      cms={cms}
                      name={'richtext_1'}
                    />
                  </div>
                )}
                <div className="section-button-row row collapse">
                  {button_1?.label && button_1?.url && (
                    <div className="column">
                      <SectionButton
                        href={button_1.url}
                        label={button_1.label}
                        cms={cms}
                        name="button_1"
                      />
                    </div>
                  )}
                  {button_2?.label && button_2?.url && (
                    <div className="column">
                      <SectionButton
                        href={button_2.url}
                        label={button_2.label}
                        cms={cms}
                        name="button_2"
                      />
                    </div>
                  )}
                </div>
                {richtext_2 && (
                  <div className="richtext-2 paragraph description">
                    <RichtextWithVariables
                      richtext={richtext_2}
                      cms={cms}
                      name={'richtext_2'}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

HeroProfessional.StyleCore = styleCore
