import {createPortal} from 'react-dom'
import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import {Button} from './index'

export default function renderOverlay(props) {
    return <>
        {
            createPortal(
                <Section
                    cms={props.cms}
                    css_overrides={props.css_overrides}
                    color_scheme_id_override={props.color_scheme_id_override}
                    page_section_id={props.page_section_id}
                    className={props.className + ' ageverification-section age-verification-section-popup'}
                    name="ageverification"
                    section_background={props.section_background}
                    meta={props.meta}>
                    <div className="section-container age-verification-section-popup">
                        <div className="section-content" style={{
                            padding: 0
                        }}>
                            <div className={`row ${props.showAnyway ? 'show-anyway' : ''}`}>
                                <div className="column">
                                    {
                                        props.showAnyway && <div style={{
                                            paddingTop: 10
                                        }}>
                                            <Button onClick={() => {
                                                props.setShowAnyway(false)
                                            }}>
                                                Stop Editing
                                            </Button>
                                        </div>
                                    }
                                    <div className="section-heading">
                                        <h1>
                                            <div className="heading-1 title">
                                                <InlineTextWithVariables name="title" value={props.title} cms={props.cms}/>
                                            </div>
                                            <div className="heading-4 subtitle">
                                                <InlineTextWithVariables name="subtitle" value={props.subtitle} cms={props.cms}/>
                                            </div>
                                        </h1>
                                    </div>
                                    <span className="section-richtext paragraph">
                                        <RichtextWithVariables name="richtext" richtext={props.richtext} cms={props.cms}/>
                                    </span>
                                    <div style={{
                                        paddingTop: 20
                                    }}>
                                        <button
                                            onClick={props.setAgeVerification}
                                            className="section-button"
                                            style={{
                                                padding: '10px 20px',
                                            }}
                                        >Yes, I am 18 or older</button>
                                    </div>

                                    <div style={{
                                        paddingTop: 10
                                    }}>
                                        <button
                                            onClick={props.goBack}
                                            className="link"
                                        >No, I am not 18 or older</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </Section>, document.body)
        }
    </>
}
