import SectionImage from '../shared/SectionImage'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'
import {IMAGE_FIELDS} from '../shared/InputTypesFields'
import Link from 'next/link'

// TODO: Use actual `cms`

const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  menuphotocard: {
    ...baseItem,
    label: 'Menu Photo Card Item',
    defaultItem: {
      ...baseItem.defaultItem,
      image: {
        src: 'https://tools--dev.cms.eiidev.com/placeholder/568/345',
        alt: '',
      },
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: IMAGE_FIELDS
      },
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
      {
        name: 'href',
        label: 'Link',
        component: 'text',
      }
    ],
  },
}

export const MENU_PHOTO_CARD_ITEM_BLOCKS = {
  menuphotocard: {
    Component({_name, index, data, cms}) {
      const innerContent = <>
        <div className="menu column">
          <div className="menu-card-item-border-0-1">
            <ItemWrapper index={index} cms={cms}>
              <div className="menu-card-item-border-0-2"></div>
              <div>
                {data?.image?.src ? (
                    <SectionImage
                        name="image"
                        src={data.image?.src}
                        mobile={data.image?.mobile}
                        icon={data.image?.icon}
                        alt={data.image?.alt}
                        width={data.image?.width}
                        height={data.image?.height}
                        priority={data.image?.priority}
                        sizes={data.image?.sizes}
                        cms={cms}
                        className="menu-card-item-image menu-photo-card-item-image"
                    />
                ) : null}
                <div className="menu-card-item menu-photo-card-item">
                  <div className="menu-photo-card-content">
                    <h3 style={{textAlign: 'center'}}>
                          <span className="menu-item-title menu-card-item-title menu-photo-card-item-title">
                            <SectionText name="title" text={data.title} cms={cms}/>
                          </span>
                      <br/>
                      <span className="menu-item-subtitle menu-card-item-subtitle menu-photo-card-item-subtitle">
                            <SectionText name="subtitle" text={data.subtitle} cms={cms}/>
                          </span>
                    </h3>
                    <p className="menu-card-item-description" style={{textAlign: 'center'}}>
                      <RichtextWithVariables
                          name="description"
                          richtext={data.description}
                          cms={cms}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </ItemWrapper>
          </div>
        </div>
        <div className="menu-item-break"></div>
      </>
      if (!data.href) {
        return innerContent
      }
        return (
            <Link href={data.href}>
                {innerContent}
            </Link>
        )
    },
    template: itemTemplates.menuphotocard,
  },
}

function ItemWrapper(props) {
  return (
      <LucidBlocksControls
          index={props.index}
          focusRing={{offset: 16, borderRadius: 4}}
          insetControls={false}
          cms={props.cms}
      >
        {props.children}
      </LucidBlocksControls>
  )
}

