import Section from '../shared/Section'
import {InlineTextWithVariables, RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/TextblockImage/presets'

export default function TextblockImage(props) {
  const {
    title,
    subtitle,
    intro,
    richtext,
    button_main,
    image,
    icon,
    outro,
    caption,
    icon_caption,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props
  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' textblock-image-section stack'}
      name="textblockimage"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content textblock_image_gutter_row_1 textblock_image_gutter_column_1">
          <div className="section-heading">
            <span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
            <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column textblock">
              <div className="section-richtext paragraph">
                <p>
                  <RichtextWithVariables richtext={richtext} cms={cms} name={'richtext'}/>
                </p></div>
              {
                  button_main?.url && (
                      <div className="column-1-button">
                        <SectionButton cms={cms} href={button_main?.url} name={'body_button'} label={button_main?.label}/>
                      </div>
                  )
              }
            </div>
            <div className="column figure">
              <figure>
                <div>
                  <div>
                    <SectionImage
                      name="image"
                      src={image?.src}
                      mobile={image?.mobile}
                      icon={image?.icon}
                      alt={image?.alt}
                      width={image?.width}
                      height={image?.height}
                      priority={image?.priority}
                      sizes={image?.sizes}
                      cms={cms}
                    />
                  </div>
                  {
                      icon?.length > 0 && (
                          <div className="color-scheme-1-svg">
                            <InlineTextWithVariables cms={cms} name="icon" value={icon}/>
                          </div>
                      )
                  }
                </div>
                {
                    caption?.length > 0 && (<figcaption className="paragraph">
                      <RichtextWithVariables richtext={caption} cms={cms} name={'caption'}/>
                    </figcaption>)
                }
                {
                    icon?.length > 0 && icon_caption > 0 && (<figcaption className="paragraph">
                      <RichtextWithVariables richtext={icon_caption} cms={cms} name={'icon_caption'}/>
                    </figcaption>)
                }
              </figure>
            </div>
          </div>
          {
                outro?.length > 0 && (<div className="section-outro">
                <div className="row">
                  <div className="column section-richtext paragraph">
                    <RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/>
                  </div>
                </div>
              </div>)
          }
          <span className={'section-c-button'}>
            <SectionButton cms={cms} href={button?.url} name={'button'} label={button?.label}/>
          </span>
        </div>
      </div>
    </Section>
  )
}

TextblockImage.StyleCore = styleCore
TextblockImage.presets = presets
