import {useState} from 'react'
import Section from '../shared/Section'
import SectionText from '../shared/SectionText'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionImage from '../shared/SectionImage'
import SectionButton from '../shared/SectionButton'
import StreetView from './StreetView'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/OfficeTour/presets'


export default function OfficeTour(props) {
  const {
    title,
    subtitle,
    intro,
    poster,
    iframe,
    width,
    height,
    caption,
    outro,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props

  const [tourIsActive, setTourIsActive] = useState(false)

  return (    
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section officetour-section stack pause'}
      name="officetour"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <span className="title heading-1">
                <SectionText name="title" text={title} cms={cms}/>
              </span>
              <span className="subtitle heading-2">
                <SectionText name="subtitle" text={subtitle} cms={cms}/>
              </span>
            </h1>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext paragraph">
                  <RichtextWithVariables cms={cms} name="intro" richtext={intro}/>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="media">
            {tourIsActive && iframe ? (
              <div>
                <StreetView
                  name="streetview"
                  embedCode={iframe}
                  width={width}
                  height={height}
                />
              </div>
            ) : (
              <div className="overlay-container" onClick={() => setTourIsActive(true)}>
                <span className="overlay-text">Click to activate virtual tour</span>
                  {poster ? (
                    <SectionImage
                      name='poster'
                      src={poster?.src}
                      mobile={poster?.mobile}
                      icon={poster?.icon}
                      alt={poster?.alt}
                      height={poster?.height}
                      width={poster?.width}
                      priority={poster?.priority}
                      sizes={poster?.sizes}
                      layout='fill'
                      cms={cms}/>
                  ) : null}
              </div>
            )}
            </div>
          </div>
          <div className="row">
            <div className="column">
              <div className="default section-richtext caption">
                <RichtextWithVariables
                  name="caption"
                  richtext={caption}
                  cms={cms}
                />
              </div>
            </div>
          </div>
          <div className="section-outro">
            <div className="row">
              <div
                className="column section-richtext"
              >
                <RichtextWithVariables
                  name="outro"
                  richtext={outro}
                  cms={cms}
                />
              </div>
            </div>
          </div>

          <div className="button-container">
            {button?.label && (
              <SectionButton
                href={button.url}
                label={button.label}
                cms={cms}
                name="button"
              />
            )}
          </div>
        </div>
      </div>
    </Section>
  )
}

OfficeTour.StyleCore = styleCore
OfficeTour.presets = presets
