import {BlockContents, SiteContentValues} from '@/components/shared/types'
import Helpers from '@/src/utils/shared/helpers'

function getGlobalVariables(siteContent: SiteContentValues) {
  const d = new Date()
  const yearEstablished = siteContent?.practice?.yearEstablished ? `${siteContent.practice.yearEstablished} - ` : ''
  let disclaimer = '*Disclaimer: results are not guaranteed, may not be permanent, and can vary per individual. Some images are of models, not actual patients.'
  let copyright = `©${yearEstablished}${d.getFullYear()} ${siteContent?.practice?.name ?? ''} | Lucid 3.0® | Designed & Developed by `
  if (siteContent?.practice?.type === 'Dental') {
    copyright += '<a href="https://www.einsteindental.com/contact/einstein-dental" rel="nofollow noopener" target="_blank">Einstein Dental</a>'
  } else if (siteContent?.practice?.type === 'Legal') {
    disclaimer = '*Disclaimer: the information provided by this website is for informational purposes only and should not be considered legal advice or a substitute for competent legal counsel.'
    copyright += '<a href="https://www.einsteinlaw.com/contact/einstein-law" rel="nofollow noopener" target="_blank">Einstein Law</a>'
  } else {
    copyright += '<a href="https://www.einsteinmedical.com/contact/einstein-medical" rel="nofollow noopener" target="_blank">Einstein Medical</a>'
  }

  return {
    year: d.getFullYear(),
    month: Helpers.format.human.date.month(d.getMonth() + 1),
    date: Helpers.format.human.date.long(d),
    disclaimer,
    copyright
  }
}

/**
 * parseContentVariables replaces variables of the form `$variable.prop ` (note the trailing space) in content with values of the form `{variable: {prop: value}}`
 * from contentVariables without using regex. If the variable is not found in contentVariables, it is left as is
 */
export function parseContentVariables(content: string, contentVariables: BlockContents, previouslyParsedString: string = ''): string {

  const contentVariablesWithGlobals = {...contentVariables, ...getGlobalVariables(contentVariables as SiteContentValues)}
  if (!content) return previouslyParsedString
  const variableStart = content.indexOf('$')
  if (variableStart === -1) {
    return previouslyParsedString + content
  }

  const endingChars = [' ', ',', '\'', ';', '!', '?', ')', ']', '}', ':', '\t', '\n', '<']
  const variableEnd = endingChars.reduce((acc, char) => {
    const index = content.indexOf(char, variableStart)
    if (index === -1) return acc
    if (acc === -1) return index
    return Math.min(acc, index)
  }, -1)

  const variable = content.substring(variableStart + 1, variableEnd === -1 ? content.length : variableEnd)
  const variableValue = getVariableValue(variable, contentVariablesWithGlobals)
  if (typeof variableValue === 'undefined') {
    return parseContentVariables(content.substring(variableStart + 1), contentVariablesWithGlobals, previouslyParsedString + content.substring(0, variableStart + 1))
  }

  const newAccumulator = previouslyParsedString + content.substring(0, variableStart) + variableValue
  return parseContentVariables(content.substring(variableEnd === -1 ? content.length : variableEnd), contentVariablesWithGlobals, newAccumulator)
}

function getVariableValue(variable: string, contentVariables: BlockContents): string | undefined {
  const delimiter: number = variable.indexOf('.')
  const currentKey: string = delimiter > -1 ? variable.substring(0, delimiter) : variable.substring(0)
  const rest = variable.substring(delimiter + 1)
  const value = contentVariables?.[currentKey] as string | BlockContents

  if (typeof value === 'object') {
    return getVariableValue(rest, value)
  }

  return value
}
