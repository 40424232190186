import Section from '../shared/Section'
import {useLucidContext} from '../../../state/ServerSideStore'
import {useEffect, useState} from 'react'
import {styleCore} from './stylecore'
import dynamic from 'next/dynamic'
import styled from 'styled-components'

export const Button = styled.button`
    padding: 10px 20px;
    color: #fff;
    z-index: 12;
    background-color: var(--nextui-colors-primary);
    
    &.disabled {
        background-color: var(--nextui-colors-primaryLight);
        opacity: 0.9;
        pointer-events: none;
    }
`

export default function AgeVerification(props) {
    const {
        meta,
        cms,
        css_overrides,
        color_scheme_id_override,
        className,
        page_section_id
    } = props

    const [{schemes}] = useLucidContext(cms)
    const [verified, setVerified] = useState(false)
    const [showAnyway, setShowAnyway] = useState(false)

    const showAgeVerification = (!verified && !cms) || showAnyway

    useEffect(() => {
        const ageVerified = localStorage.getItem('ageVerified')
        setVerified(ageVerified === 'true')
    }, [])

    function resetAgeVerification() {
        localStorage.setItem('ageVerified', 'false')
        setVerified(false)
    }

    function doSetShowAnyway() {
        setShowAnyway(true)
    }

    function setAgeVerification() {
        localStorage.setItem('ageVerified', 'true')
        setVerified(true)
        setShowAnyway(false)
    }

    function goBack() {
        if (!cms) {
            window.history.back()
        }
    }

    const Overlay = dynamic(() => import('./overlay'), {
        ssr: false
    })


    return (
        <Section
            cms={cms}
            css_overrides={css_overrides}
            color_scheme_id_override={color_scheme_id_override}
            page_section_id={page_section_id}
            className={className + ' ageverification-section' + ((showAgeVerification) ? 'age-verification-section-popup' : '')}
            name="ageverification"
            meta={meta}>
            {
                cms && <div className="age-verification-placeholder">
                    <strong className="av-title">This section is invisible and will not show in the previewed & rendered pages.</strong>
                    <Button onClick={resetAgeVerification} className={`button ${!verified ? 'disabled' : ''}`}>{
                        verified ? 'Reset Age Verification' : 'Age Verification Reset'
                    }</Button>
                    <Button onClick={doSetShowAnyway} className={`button ${showAnyway ? 'disabled' : ''}`}>Edit Popup Text</Button>
                </div>
            }
            {
                showAgeVerification && <Overlay {...props} setAgeVerification={setAgeVerification} goBack={goBack} showAnyway={showAnyway} setShowAnyway={setShowAnyway}/>
            }
        </Section>
    )
}

AgeVerification.StyleCore = styleCore
