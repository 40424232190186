import {useEffect, useState} from 'react'
import {useLucidContext} from '@/src/state/ServerSideStore'
import type {
  ColorSchemeComponentContentResponse,
  ColorSchemeResponse,
  ColorSchemeValues,
} from '@/components/managers/ColorSchemeManager/ColorScheme'

export function useColorScheme(initialSchemes: any[], color_scheme_id_override: string | null, cms: boolean) {
  const [lucidSiteStore] = useLucidContext(true)
  const [currentScheme, setCurrentScheme] = useState<ColorSchemeValues | undefined>(
    computeColorSchemeValues(initialSchemes).find((scheme) => {
      if (color_scheme_id_override) {
        return scheme.colorSchemeID === color_scheme_id_override
      }
      return scheme.active
    })
  )

  useEffect(() => {
    changeColorScheme(color_scheme_id_override ?? lucidSiteStore.schemes.find((scheme) => scheme.active)?.id)
  }, [lucidSiteStore.schemes, color_scheme_id_override])

  const changeColorScheme = (id?: string | null): void => {
    let scheme = null
    /**
     * We will sort schemes so that we always get the same default/first scheme
     */
    const schemes = lucidSiteStore.schemes.sort((a, b) => Number(b.id) - Number(a.id))
    if (!id) {
      scheme = schemes.find((scheme) => Boolean(scheme.id)) ?? schemes[0]
    } else {
      scheme = schemes.find((scheme) => scheme.id === id) ?? schemes[0]
    }
    if (scheme) {
      setCurrentScheme(computeColorSchemeValues([scheme]).pop())
    }
  }

  return [currentScheme, changeColorScheme]
}

export const computeInitialValues = (incomingColorSchemes?: ColorSchemeResponse, appendIDs = true) => {
  const addition: ColorSchemeValues | any = {}
    if (typeof incomingColorSchemes === 'undefined') return addition
  if (appendIDs) addition.colorSchemeID = incomingColorSchemes.id
  for (const additionElement of incomingColorSchemes.components) {
    const componentType = reformatComponentTypeKey(additionElement.type)
    addition[additionElement.label as keyof ColorSchemeValues] = additionElement.component_content[componentType as keyof ColorSchemeComponentContentResponse] as ColorSchemeValuesValue
  }

  if (typeof incomingColorSchemes.children_color_schemes !== 'undefined') {
    for (const childColorScheme of incomingColorSchemes.children_color_schemes) {
      addition[childColorScheme.name as keyof ColorSchemeValues] = computeInitialValues(childColorScheme, appendIDs)
    }
  }
  return addition
}

export const computeColorSchemeValues = (colorSchemes: ColorSchemeResponse[]) => {
  return colorSchemes.map((scheme) => {
    return {
      rootColorSchemeId: scheme.id,
      scheme_name: scheme.name,
      name: scheme.name,
      active: scheme.active,
      ...computeInitialValues(scheme)
    }
  }) as ColorSchemeValues[]
}

export const reformatComponentTypeKey = (key: string): string => {
  const uc = key.toUpperCase()
  if (['URL', 'SVG'].includes(uc)) {
    return uc
  }
  return key.toLowerCase()
}

export type ColorSchemeValuesValue = ColorSchemeValues | ColorSchemeValues[keyof ColorSchemeValues]
