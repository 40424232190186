import BlogCard from '@/components/sections/BlogCard/index'

export const styleCore = {
  config: {
    cards: {
      content: {
        backgroundColor: {
          content: 'color',
          default: '#ffffff'
        }
      }
    }
  },
  target: {
    componentName: 'blogcard',
  },
  css: `
    {target} .menu-card-item-border-0-1 {
      background-color: {cards.backgroundColor};
    }
  `
}
