import {Button, Modal, Text, useModal} from '@nextui-org/react'
import BlogPostPicker from './BlogPostPicker'
import {useState} from 'react'
import {RSSItem} from '../shared/RSSReader'
import {BlogItemsProps, BlogPost} from '../../shared/types'
import type {AnyField} from '@einsteinindustries/tinacms-forms'

export const getBlogBlocks = (BlogItemsComponent: React.ComponentType<BlogItemsProps>, hasAuthor: boolean = false) => ({
  blog_posts: {
    template: {
      label: 'Blog Posts',
      defaultItem: getDefaultItems(hasAuthor),
      fields: getFields(hasAuthor)
    },
    Component({index, cms, data}: {index: number, cms: boolean, data: {blog_posts: BlogPost[], show_author?: boolean}}) {
      return (
        <BlogItemsComponent
          index={index}
          cms={cms}
          blog_posts={data.blog_posts}
          showAuthor={data.show_author ?? false}
        />
      )
    }
  }
})

const getDefaultItems = (hasAuthor: boolean) => {
  return hasAuthor ? {
    show_author: true,
    blog_posts: []
  } : {
    blog_posts: []
  }
}

const getFields = (hasAuthor: boolean) => {
  const baseFields: AnyField[] = []
  
  if (hasAuthor) {
    baseFields.push({
      name: 'show_author',
      label: 'Show Author',
      component: 'toggle'
    })
  }

  baseFields.push({
    name: 'blog_posts',
    label: 'Blog Posts',
    component: ({form, field}: {form: any, field: any}) => {
      const ModalWrapper = () => {
        const {setVisible, bindings} = useModal()
        const [selectedPosts, setSelectedPosts] = useState<RSSItem[]>([])

        const handleConfirmSelection = () => {
          form.change(field.name, selectedPosts)
          setVisible(false)
        }

        return <>
          <Button style={{width: '100%'}} auto size="lg" onPress={() => setVisible(true)}>Browse Blog Posts</Button>
          <Modal scroll width="600px" aria-labelledby="modal-title" {...bindings}>
            <Modal.Header>
              <Text id="modal-title" size={18} b>
                Browse Blog Posts
              </Text>
            </Modal.Header>
            <Modal.Body css={{p: 0}}>
              <BlogPostPicker setSelectedPosts={setSelectedPosts} />
            </Modal.Body>
            <Modal.Footer>
              <Button auto flat color="error" onPress={() => setVisible(false)}>
                Close
              </Button>
              <Button auto onPress={handleConfirmSelection}>
                Confirm Selection
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      }

      return <ModalWrapper />
    }
  })

  return baseFields
}
