import {InlineTextWithVariables, RichtextWithVariables} from '@/components/sections/shared/SectionsWithVariables'

type SectionQuoteFormWrapperProps = {
  children: React.ReactNode;
  cms: boolean;
  title: string;
  subtitle: string;
  intro?: string;
  outro?: string;
  quote: string;
  author: string;
  className: string;
}
export const SectionQuoteFormWrapper = ({children, cms, title, subtitle, intro, outro, quote, author, className} : SectionQuoteFormWrapperProps) => {

  let quoteComponent = null
  let quoteClass = ''
  let authorComponent = null
  if (author) {
    authorComponent = (
      <cite className="small"><InlineTextWithVariables value={author} cms={cms} name={'author'}/></cite>
    )
  }
  if (quote) {
    quoteComponent = (
      <div className="column">
        <blockquote>
          <span className="quote"><RichtextWithVariables richtext={quote} cms={cms} name={'quote'}/></span>
          {authorComponent}
        </blockquote>
      </div>
    )
    quoteClass = ' has-quote'
  }

  let introComponent = null
  if (intro) {
    introComponent = (
      <div className="section-description">
        <div className="row">
          <div className="column">
            <div className="section-richtext">
              <RichtextWithVariables richtext={intro} cms={cms} name={'intro'}/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  let outroComponent = null
  if (outro) {
    outroComponent = (
      <div className="section-outro">
        <div className="row">
          <div className="column section-richtext">
            <RichtextWithVariables richtext={outro} cms={cms} name={'outro'}/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="section-container">
      <div className="section-content consultation_gutter_row_1 consultation_gutter_column_1">
        <div className="section-form">
          <div className={`row quote-middle flexbox-alignment middle mobile-middle ${quoteClass}`}>
            <div className="column">
              <div className="contact-card-border-0-1">
                <div className="contact-card-border-0-2"></div>
                <div className="contact-card-item">
                  <div className={`section-heading flexbox-alignment middle mobile-middle ${quoteClass}`}>
                    <span className="title heading-1"><RichtextWithVariables richtext={title} cms={cms} name={'title'}/></span>
                    <span className="subtitle heading-2"><RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/></span>
                  </div>
                  {introComponent}
                  <div className="contact-form section-richtext">
                    {children}
                  </div>
                  {outroComponent}
                </div>
              </div>
            </div>
            <div className="column">
              {quoteComponent}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
