import {Box} from '@/components/sections/shared/stylecore/sets/Box'

export const Defaults = {
  createFields(defaults = {}, prefix = '') {
    const defaultFields = {
      section: {
        background: {
          color: '',
          image: '',
          repeat: 'no-repeat',
          size: 'cover',
          horizontalPosition: 'center',
          verticalPosition: 'center',
          horizontalOffset: '',
          verticalOffset: '',
          attachment: 'scroll',
          opacity: 1,
        },
        animations: {
          section: {
            enableAnimation: false,
            animationDuration: 1,
            animationType: 'fadeInUp',
            animationDelay: 0,
          },
          text: {
            enableAnimation: false,
            animationDuration: 1,
            animationType: 'fadeInUp',
            animationDelay: 0,
            appliesTo: '{target} .heading-1, {target} .heading-2, {target} .heading-3, {target} .heading-4, {target} .heading-5, {target} .heading-6, {target} .paragraph, {target} a, {target} span',
            stagger: true,
          },
          background: {
            enableAnimation: false,
            animationDuration: 1,
            animationType: 'fadeInUp',
            animationDelay: 0,
          }
        },
        links: {
          hideUnderline: true
        }
      },
      lists: {
        ordered: {
          columns: 2,
          ruled: false,
          verticalSpacing: 0,
          listItemIndent: 0,
          listStyleType: 'decimal',
        },
        unordered: {
          columns: 2,
          ruled: false,
          verticalSpacing: 0,
          listItemIndent: 0,
          listStyleType: 'disc',
        }
      }
    }
    const animationTypes = [
      {
        'label': 'None',
        'value': 'none'
      },
      {
        'label': 'Fade In Up',
        'value': 'fadeInUp'
      },
      {
        'label': 'Fade In',
        'value': 'fadeIn'
      }
    ]
    const fields = Object.assign(defaultFields, defaults)
    return {
      section: {
        content: {
          animations: {
            content: {
              section: {
                content: {
                  enableAnimation: {
                    content: 'toggle',
                    default: fields.section.animations.section.enableAnimation,
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.background.attachment === 'fixed') {
                        return {
                          ...field,
                          locked: true,
                          default: false,
                          message: {
                            state: 'warning',
                            message: 'Section animations are not supported when the background attachment is set to fixed. Try enabling text animations instead.'
                          }
                        }
                      }
                    }
                  },
                  animationType: {
                    content: 'select',
                    default: fields.section.animations.section.animationType,
                    options: animationTypes,
                    description: 'The animation type',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.section.enableAnimation ? fieldState : 'none'
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.section.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDuration: {
                    content: 'number',
                    default: fields.section.animations.section.animationDuration,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The duration of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.section.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.section.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDelay: {
                    content: 'number',
                    default: fields.section.animations.section.animationDelay,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The delay of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.section.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.section.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                }
              },
              text: {
                content: {
                  enableAnimation: {
                    content: 'toggle',
                    default: fields.section.animations.text.enableAnimation,
                  },
                  animationType: {
                    content: 'select',
                    default: fields.section.animations.text.animationType,
                    options: animationTypes,
                    description: 'The animation type',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.text.enableAnimation ? fieldState : 'none'
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.text.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDuration: {
                    content: 'number',
                    default: fields.section.animations.text.animationDuration,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The duration of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.text.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.text.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  stagger: {
                    content: 'toggle',
                    default: fields.section.animations.text.stagger,
                    description: 'Stagger the animation of the text elements',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.text.enableAnimation ? fieldState : false
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.text.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDelay: {
                    content: 'number',
                    default: fields.section.animations.text.animationDelay,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The delay of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.text.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.text.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  appliesTo: {
                    content: 'select',
                    default: fields.section.animations.text.appliesTo,
                    options: [
                      {
                        label: 'All',
                        value: '{target} .heading-1, {target} .heading-2, {target} .heading-3, {target} .heading-4, {target} .heading-5, {target} .heading-6, {target} .paragraph, {target} a, {target} span'
                      },
                      {
                        label: 'Headings',
                        value: '{target} .heading-1, {target} .heading-2, {target} .heading-3, {target} .heading-4, {target} .heading-5, {target} .heading-6'
                      },
                      {
                        label: 'Paragraph',
                        value: '{target} .paragraph'
                      }
                    ]
                  }
                }
              },
              background: {
                content: {
                  enableAnimation: {
                    content: 'toggle',
                    default: fields.section.animations.background.enableAnimation,
                  },
                  animationType: {
                    content: 'select',
                    default: fields.section.animations.background.animationType,
                    options: animationTypes,
                    description: 'The animation type',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.background.enableAnimation ? fieldState : 'none'
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.background.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDuration: {
                    content: 'number',
                    default: fields.section.animations.background.animationDuration,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The duration of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.background.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.background.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  },
                  animationDelay: {
                    content: 'number',
                    default: fields.section.animations.background.animationDelay,
                    min: 0,
                    max: 10,
                    step: 0.1,
                    description: 'The delay of the animation in seconds',
                    postProcessing: (formState, fieldState) => {
                      return formState.general.section.animations.background.enableAnimation ? fieldState : 0
                    },
                    listener: (formState, fieldState, field) => {
                      if (formState.general.section.animations.background.enableAnimation) {
                        return field
                      }

                      return {
                        ...field,
                        locked: true
                      }
                    }
                  }
                }
              },
            }
          },
          background: {
            content: {
              color: {
                content: 'color',
                default: fields.section.background.color,
                description: 'The background color of the section',
              },
              repeat: {
                content: 'select',
                default: fields.section.background.repeat,
                options: [
                  {value: 'no-repeat', label: 'No Repeat'},
                  {value: 'repeat', label: 'Repeat'},
                  {value: 'repeat-x', label: 'Repeat X'},
                  {value: 'repeat-y', label: 'Repeat Y'},
                ],
                description: 'The background repeat of the section',
              },
              size: {
                content: 'select',
                default: fields.section.background.size,
                options: [
                  {value: 'cover', label: 'Cover'},
                  {value: 'contain', label: 'Contain'},
                ],
                description: 'The background size of the section',
              },
              attachment: {
                content: 'select',
                default: fields.section.background.attachment,
                options: [
                  {value: 'scroll', label: 'Scroll'},
                  {value: 'fixed', label: 'Fixed'},
                ],
                description: 'The background attachment of the section',
                listener: (formState, fieldState, field) => {
                  if (formState.general.section.animations.section.enableAnimation) {
                    return {
                      ...field,
                      locked: true,
                      default: 'scroll',
                      message: {
                        state: 'warning',
                        message: 'Background attachment must be set to scroll when section animations are enabled. Try enabling text animations instead.'
                      }
                    }
                  }
                }
              },
              horizontalPosition: {
                content: 'select',
                default: fields.section.background.horizontalPosition,
                options: [
                  {value: 'center', label: 'Center'},
                  {value: 'left', label: 'Left'},
                  {value: 'right', label: 'Right'},
                ],
                description: 'The horizontal position of the background image',
              },
              verticalPosition: {
                content: 'select',
                default: fields.section.background.position,
                options: [
                  {value: 'center', label: 'Center'},
                  {value: 'top', label: 'Top'},
                  {value: 'bottom', label: 'Bottom'},
                ],
                description: 'The vertical position of the background image',
              },
              horizontalOffset: {
                content: 'number',
                default: fields.section.background.horizontalOffset,
                description: 'Has no effect when the horizontal background position is set to center',
                unit: 'px',
              },
              verticalOffset: {
                content: 'number',
                default: fields.section.background.verticalOffset,
                description: 'Has no effect when the vertical background position is set to center',
                unit: 'px',
              },
              opacity: {
                content: 'number',
                default: fields.section.background.opacity,
                description: 'The opacity of the background image',
                min: 0,
                max: 1
              }
            }
          },
          enableWidthOverride: {
            content: 'toggle',
            default: false,
            description: 'Enable the max-width limit',
          },
          maxWidth: {
            content: 'number',
            unit: 'px',
            default: 1200,
          },
          stacking: {
            content: {
              enableStacking: {
                content: 'toggle',
                default: false,
                message: {
                  state: 'warning',
                  message: 'Stacking should not be used in new sites, as it is now a legacy feature'
                }
              },
              order: {
                content: 'select',
                default: 'back',
                options: [
                  {value: 'front', label: 'Front'},
                  {value: 'back', label: 'Back'},
                ],
                locked: true,
                listener: (formState, fieldState, field) => {
                    if (formState.general.section.stacking.enableStacking) {
                        return {
                          ...field,
                          locked: false,
                        }
                    }
                    return {
                        ...field,
                        locked: true
                    }
                }
              }
            }
          },
          links: {
            content: {
              hideUnderline: {
                content: 'toggle',
                default: fields.section.links.hideUnderline,
                description: 'Show underline on links'
              }
            }
          }
        }
      },
      typography: {
        content: {
          typographyOverride: {
            label: 'Typography Group',
            content: 'typography',
            default: 'default',
          }
        },
      },
      buttons: {
        content: {
          transition: {
            content: {
              transitionDuration: {
                content: 'number',
                default: 0.3,
                min: 0,
                max: 10,
                step: 0.1,
                description: 'The duration of the button transition in seconds',
              },
              transitionTimingFunction: {
                content: 'select',
                default: 'ease',
                options: [
                  {value: 'ease', label: 'Ease'},
                  {value: 'ease-in', label: 'Ease In'},
                  {value: 'ease-out', label: 'Ease Out'},
                  {value: 'ease-in-out', label: 'Ease In Out'},
                  {value: 'ease-in-circ', label: 'Ease In Circ'},
                  {value: 'ease-out-circ', label: 'Ease Out Circ'},
                  {value: 'ease-in-out-circ', label: 'Ease In Out Circ'},
                  {value: 'linear', label: 'Linear'},
                ],
                description: 'The timing function of the button transition',
              },
            }
          },
          ...Box.createFields('defaultState', {
            display: {
              mode: 'inherit',
              positioning: {
                position: 'inherit',
              }
            }
          }),
          ...Box.createFields('hoverState', {
            display: {
              mode: 'static',
              positioning: {
                position: 'inherit',
              }
            }
          }),
        }
      },
      lists: {
        content: {
          ordered: {
            content: {
              columns: {
                content: 'number',
                default: fields.lists.ordered.columns,
                min: 1,
                max: 4,
                description: 'The number of columns in the list',
              },
              verticalSpacing: {
                content: 'number',
                default: fields.lists.ordered.verticalSpacing,
                min: 0,
                max: 200,
                description: 'The veritcal spacing between list items',
                unit: 'px',
              },
              ruled: {
                content: 'toggle',
                default: fields.lists.ordered.ruled,
                description: 'Add a rule between list items',
              },
              listItemIndent: {
                content: 'number',
                default: fields.lists.ordered.listItemIndent,
                min: 0,
                max: 200,
                description: 'The indent of the list items',
                unit: 'px',
              },
              listStyleType: {
                content: 'select',
                default: fields.lists.ordered.listStyleType,
                options: [
                  {value: 'none', label: 'None'},
                  {value: 'decimal', label: 'Decimal'},
                  {value: 'decimal-leading-zero', label: 'Decimal Leading Zero'},
                  {value: 'lower-roman', label: 'Lower Roman'},
                  {value: 'upper-roman', label: 'Upper Roman'},
                  {value: 'lower-greek', label: 'Lower Greek'},
                  {value: 'lower-latin', label: 'Lower Latin'},
                  {value: 'upper-latin', label: 'Upper Latin'},
                  {value: 'armenian', label: 'Armenian'},
                  {value: 'georgian', label: 'Georgian'},
                  {value: 'lower-alpha', label: 'Lower Alpha'},
                  {value: 'upper-alpha', label: 'Upper Alpha'},
                ],
                description: 'The type of list item marker',
              },
            }
          },
          unordered: {
            content: {
              columns: {
                content: 'number',
                default: fields.lists.unordered.columns,
                min: 1,
                max: 4,
                description: 'The number of columns in the list',
              },
              verticalSpacing: {
                content: 'number',
                default: fields.lists.unordered.verticalSpacing,
                min: 0,
                max: 200,
                description: 'The veritcal spacing between list items',
                unit: 'px',
              },
              ruled: {
                content: 'toggle',
                default: fields.lists.unordered.ruled,
                description: 'Add a rule between list items',
              },
              listItemIndent: {
                content: 'number',
                default: fields.lists.unordered.listItemIndent,
                min: 0,
                max: 200,
                description: 'The indent of the list items',
                unit: 'px',
              },
              listStyleType: {
                content: 'select',
                default: fields.lists.unordered.listStyleType,
                options: [
                  {value: 'none', label: 'None'},
                  {value: 'disc', label: 'Disc'},
                  {value: 'circle', label: 'Circle'},
                  {value: 'square', label: 'Square'},
                ],
                description: 'The type of list item marker',
              },
            }
          },
        }
      }
    }

  },
  createCSS(prefix = '') {
    return `
      @keyframes fadeInUp {
        from {
          transform: translate3d(0,40px,0)
        }
        to {
          transform: translate3d(0,0,0);
          opacity: 1
        }
      }
      @-webkit-keyframes fadeInUp {
        from {
          transform: translate3d(0,40px,0)
        }
        to {
          transform: translate3d(0,0,0);
          opacity: 1
        }
      }

      @keyframes fadeIn {
        from {
          opacity: 0
        }
        to {
          opacity: 1
        }
      }
      @-webkit-keyframes fadeInUp {
        from {
          opacity: 0
        }
        to {
          opacity: 1
        }
      }

      {target} .section-container {
        max-width: {${prefix}section.enableWidthOverride ? {${prefix}section.maxWidth} : none};
        margin-left: auto;
        margin-right: auto;
      }

      {target} .button {
        transition: all {${prefix}buttons.transition.transitionDuration}s {${prefix}buttons.transition.transitionTimingFunction};
      }

      ${Box.createCSS('button', prefix + 'buttons.defaultState.')}
      ${Box.createCSS('button:hover', prefix + 'buttons.hoverState.')}

      {target} .section-container {
        box-sizing: border-box;
      }

      {target} .section-background {
        background-color: {${prefix}section.background.color};
      }

      {target}.front-stack .section-background {
        max-width: {${prefix}section.enableWidthOverride ? {${prefix}section.maxWidth} : none};
      }

      {target} {
        opacity: {${prefix}section.animations.section.enableAnimation ? 0 : 1};
        animation-name: {${prefix}section.animations.section.animationType};
        animation-duration: {${prefix}section.animations.section.animationDuration}s;
        animation-delay: {${prefix}section.animations.section.animationDelay}s;
        animation-fill-mode: {${prefix}section.animations.section.enableAnimation ? both : none};
        -webkit-animation-name: {${prefix}section.animations.section.animationType};
        -webkit-animation-duration: {${prefix}section.animations.section.animationDuration}s;
        -webkit-animation-delay: {${prefix}section.animations.section.animationDelay}s;
        -webkit-animation-fill-mode: {${prefix}section.animations.section.enableAnimation ? both : none};
      }

      {${prefix}section.animations.text.appliesTo} {
        opacity: {${prefix}section.animations.text.enableAnimation ? 0 : 1};
        animation-name: {${prefix}section.animations.text.animationType};
        animation-duration: {${prefix}section.animations.text.animationDuration}s;
        animation-delay: {${prefix}section.animations.text.animationDelay}s;
        animation-fill-mode: {${prefix}section.animations.text.enableAnimation ? both : none};
        -webkit-animation-name: {${prefix}section.animations.text.animationType};
        -webkit-animation-duration: {${prefix}section.animations.text.animationDuration}s;
        -webkit-animation-delay: {${prefix}section.animations.text.animationDelay}s;
        -webkit-animation-fill-mode: {${prefix}section.animations.text.enableAnimation ? both : none};
      }

      {target} .heading-1 {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.1s : 0s};
      }

      {target} .heading-2 {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.3s : 0s};
      }

      {target} .heading-3 {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.5s : 0s};
      }

      {target} .heading-4 {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.7s : 0s};
      }

      {target} .heading-5 {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.9s : 0s};
      }

      {target} .heading-6 {
        animation-delay: {${prefix}section.animations.text.stagger ? 1.1s : 0s};
      }

      {target} .paragraph, {target} a {
        animation-delay: {${prefix}section.animations.text.stagger ? 0.7s : 0s};
      }

      {target} .section-image-background {
        animation-name: {${prefix}section.animations.background.animationType};
        animation-duration: {${prefix}section.animations.background.animationDuration}s;
        animation-delay: {${prefix}section.animations.background.animationDelay}s;
        animation-fill-mode: {${prefix}section.animations.background.enableAnimation ? both : none};
        -webkit-animation-name: {${prefix}section.animations.background.animationType};
        -webkit-animation-duration: {${prefix}section.animations.background.animationDuration}s;
        -webkit-animation-delay: {${prefix}section.animations.background.animationDelay}s;
        -webkit-animation-fill-mode: {${prefix}section.animations.background.enableAnimation ? both : none};
      }

      {target} ul {
        column-count: {${prefix}lists.unordered.columns};
        list-style-type: {${prefix}lists.unordered.listStyleType};
      }
      {target} ol {
        column-count: {${prefix}lists.ordered.columns};
        list-style-type: {${prefix}lists.ordered.listStyleType};
      }

      {target} ul li {
        margin-bottom: {${prefix}lists.unordered.verticalSpacing};
        padding-left: {${prefix}lists.unordered.listItemIndent};
        border-bottom: {${prefix}lists.unordered.ruled ? 1px solid #000 : none};
        list-style-type: inherit;
      }
      {target} ol li {
        margin-bottom: {${prefix}lists.ordered.verticalSpacing};
        padding-left: {${prefix}lists.ordered.listItemIndent};
        border-bottom: {${prefix}lists.ordered.ruled ? 1px solid #000 : none};
        list-style-type: inherit;
      }
      
      {target} .link {
        border-bottom-color: {${prefix}section.links.hideUnderline ? transparent : inherit};
      }
    `
  }
}

/***
 * Removed some interfering code temporarily. Will be added back in later.
 * Responding to recovery ticket to allow additional testing: https://www.notion.so/einsteinindustries/Heading-1-is-not-working-04d6ba159d8a481ebe70637b4134c061?pvs=4
 *
 *
 * @media (min-width: {section.container.breakpointA.minWidth}) {
 *                 {target} .section-container { width: {section.container.breakpointA.sectionWidth}; }
 *             }
 *             @media (min-width: {section.container.breakpointB.minWidth}) {
 *                 {target} .section-container { width: {section.container.breakpointB.sectionWidth}; }
 *             }
 *             @media (min-width: {section.container.breakpointC.minWidth}) {
 *                 {target} .section-container { width: {section.container.breakpointC.sectionWidth}; }
 *             }
 */
