import Section from '../shared/Section'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionButton from '../shared/SectionButton'
import {styleCore} from './stylecore'
import {presets} from '@/components/sections/Textblock/presets'

export default function Textblock(props) {
  const {
    title,
    subtitle,
    richtext,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms,
  } = props

  let sectionButton = null
  if (button?.label) {
    sectionButton = <SectionButton label={button?.label} href={button?.url} cms={cms} name={'button'}/>
  }

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section textblock-section stack'}
      name="textblock"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <span className="title heading-1">
              <RichtextWithVariables richtext={title} cms={cms} name={'title'}/>
            </span>
              <span className="subtitle heading-2">
                <RichtextWithVariables richtext={subtitle} cms={cms} name={'subtitle'}/>
            </span>
          </div>
          <div className="row">
            <div className="column">
              <div className="section-richtext">
                <p className={'paragraph'}>
                  <RichtextWithVariables richtext={richtext} cms={cms} name={'richtext'}/>
                </p>
              </div>
            </div>
          </div>
          {sectionButton}
        </div>
      </div>
    </Section>
  )
}

Textblock.StyleCore = styleCore
Textblock.presets = presets


