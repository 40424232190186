import type {BackgroundType} from '@/components/sections/shared/SectionBackground'
import useWindowDimensions, {WindowSizeKey} from '@/components/sections/shared/useWindowWidthListener'
import type {ColorSchemeValues} from '@/components/managers/ColorSchemeManager/ColorScheme'

export function getStackingOrderClasses(order: string | undefined = 'back') {
  switch (order) {
    case 'front':
      return {
        topLevel: 'front-stack',
      }
    default:
      return {
        topLevel: 'back-stack',
      }
  }
}

export function useModifyColorScheme(backgroundType: BackgroundType, pageSectionBackground: string, mobileBackgroundImage: string, mobileTransitionPoint: keyof typeof WindowSizeKey, stackingOrder?: 'front' | 'back', currentScheme?: ColorSchemeValues) {
  const {size: windowSize} = useWindowDimensions()
  if (!currentScheme) return currentScheme
  const isMobile = windowSize <= WindowSizeKey[mobileTransitionPoint]
  let showBackgroundColor = true
  if (backgroundType === 'video') {
    showBackgroundColor = false
  } else {
    const isMobileAndHasMobileBackground = isMobile && mobileBackgroundImage
    const isDesktopAndHasDesktopBackground = !isMobile && pageSectionBackground
    backgroundType === 'image' && (isDesktopAndHasDesktopBackground || isMobileAndHasMobileBackground) && (showBackgroundColor = false)
  }

  if (stackingOrder === 'back' || !stackingOrder) {
    showBackgroundColor = true
  }

  return {
    ...currentScheme,
    background_color: showBackgroundColor ? currentScheme?.background_color : 'transparent',
  }
}
