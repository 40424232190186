import {LucidBlocksControls} from '../shared/LucidInlineGroups'
import {getBlogBlocks} from '../Blog/shared'

export const BLOG_BLOCKS = getBlogBlocks(BlogItems, true)

function BlogItems({index, blog_posts, cms, showAuthor}) {
  if (!blog_posts || blog_posts.length === 0) {
    if (!cms) return null

    return (
      <ItemWrapper index={index} cms={cms}>
        <div className="blog-post">
          <div className="menu-card-item paragraph">No blog posts selected. Click the edit icon to add some.</div>
        </div>
      </ItemWrapper>
    )
  }

  return (
    <ItemWrapper index={index} cms={cms}>
      {blog_posts?.map((post, index) => {
        return (
          <div key={index} className="menu-card-item-border-0-1">
            <a href={post.link} className="menu-card-item blog-card-item">
              <span className="menu-card-item-title blog-card-item-title blog-post-title large-label heading-2">{post.title}</span>
              {post.author && showAuthor && (
                <span className="menu-card-item-subtitle blog-card-item-subtitle menu-item-subtitle section-richtext paragraph">
                  By {post.author}
                </span>
              )}
            </a>
          </div>
        )
      })}
    </ItemWrapper>
  )
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      index={props.index}
      cms={props.cms}
      focusRing={{offset: 16, borderRadius: 4}}
      insetControls={false}
      className="menu column"
    >
      {props.children}
    </LucidBlocksControls>
  )
}
