import SectionImage from '../shared/SectionImage'
import SectionText from '../shared/SectionText'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'
import {IMAGE_FIELDS} from '../shared/InputTypesFields'

// TODO: Use actual `cms`

// Base item for all the other item types to pull from
const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  // TODO: Create a set of default fields
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

// Templates to use in the parent's block config and the InlineBlocks component
const itemTemplates = {
  menuicon: {
    ...baseItem,
    label: 'Menu Icon Item',
    defaultItem: {
      ...baseItem.defaultItem,
      image: {
        src: 'https://tools--dev.cms.eiidev.com/placeholder/200/200',
        alt: '',
      },
      href: '#',
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: IMAGE_FIELDS
      },
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
      {
        name: 'href',
        label: 'Link',
        component: 'text',
      }
    ],
  },
}

export const MENU_ICON_ITEM_BLOCKS = {
  menuicon: {
    Component({_name, index, data, cms}) {
      return (
        <>
          <div className="menu column">
            <div className="menu-icon-item" style={{
              textAlign: 'center'
            }}>
              <ItemWrapper index={index} cms={cms}>
                <a href={data.href}>
                  <div className="icon-item">
                    <SectionImage
                      name="image"
                      src={data.image?.src}
                      mobile={data.image?.mobile}
                      alt={data.image?.alt}
                      icon={data.image?.icon}
                      width={data.image?.width}
                      height={data.image?.height}
                      priority={data.image?.priority}
                      sizes={data.image?.sizes}
                      cms={cms}
                    />
                  </div>
                  <h3>
                  <span className="menu-item-title menu-icon-item-title heading-3">
                    <SectionText name="title" text={data.title} cms={cms}/>
                  </span>
                    <br/>
                    <span className="menu-item-subtitle menu-icon-item-subtitle heading-4">
                    <SectionText name="subtitle" text={data.subtitle} cms={cms}/>
                  </span>
                  </h3>
                  <span className="section-richtext paragraph"><RichtextWithVariables
                    name="description"
                    richtext={data.description}
                    cms={cms}
                  /></span>
                </a>
              </ItemWrapper>
            </div>
          </div>
          <div className="menu-item-break"></div>
        </>
      )
    },
    template: itemTemplates.menuicon,
  },
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      index={props.index}
      cms={props.cms}
      focusRing={{offset: 16, borderRadius: 4}}
      insetControls={false}
    >
      {props.children}
    </LucidBlocksControls>
  )
}
