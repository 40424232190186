import SectionImage from '../shared/SectionImage'
import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import constructAssetUrl from '../../editor/shared/constructAssetUrl'
import {LucidBlocksControls} from '../shared/LucidInlineGroups'
import {LinkWrapper} from '../shared/LinkWrapper'
import {IMAGE_FIELDS} from '../shared/InputTypesFields'

// TODO: Use actual `cms`

const baseItem = {
  label: 'Item',
  defaultItem: {
    title: 'Title',
    subtitle: 'Subtitle',
  },
  itemProps: (item) => {
    if (!item.title && !item.subtitle) {
      return
    }

    return {label: item.title || item.subtitle}
  },
  fields: [
    {
      name: 'title',
      label: 'Title',
      component: 'text',
    },
    {
      name: 'subtitle',
      label: 'Subtitle',
      component: 'text',
    },
  ],
}

const itemTemplates = {
  menuphoto: {
    ...baseItem,
    label: 'Menu Photo Item',
    defaultItem: {
      ...baseItem.defaultItem,
      image: {
        src: 'https://tools--dev.cms.eiidev.com/placeholder/420/420',
        alt: '',
      },
      description:
        '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci expedita doloremque temporibus ipsum illo vero aut voluptas laborum ex dignissimos numquam at odio, aspernatur voluptatum, provident sit nesciunt! Dicta, natus.</p>',
    },
    fields: [
      ...baseItem.fields,
      {
        name: 'image',
        label: 'Image',
        component: 'group',
        fields: IMAGE_FIELDS
      },
      {
        name: 'description',
        label: 'Description',
        component: 'html',
      },
      {
        name: 'link',
        label: 'Link',
        component: 'group',
        fields: [
          {
            name: 'href',
            label: 'URL',
            component: 'text',
          },
          {
            name: 'target',
            label: 'Open in new tab',
            component: 'toggle',
          }
        ]
      }
    ],
  },
}

export const MENU_PHOTO_ITEM_BLOCKS = {
  menuphoto: {
    Component({_name, index, data, cms}) {
      return (
        <>
          <div className="menu column">
            <ItemWrapper index={index} cms={cms}>
              <LinkWrapper href={data?.link?.href} inNewWindow={data?.link?.target} cms={cms}>
              <div className="menu-photo-item">
                <div className="photo-box">
                  <div>
                    {data?.image?.src ? (
                      <SectionImage
                        name="image"
                        src={data?.image?.src}
                        mobile={data?.image?.mobile}
                        alt={data?.image?.alt}
                        icon={data?.image?.icon}
                        width={data?.image?.width}
                        height={data?.image?.height}
                        priority={data.image?.priority}
                        sizes={data.image?.sizes}
                        cms={cms}
                      />
                    ) : null}
                  </div>
                </div>
                <h3 style={{textAlign: 'center'}}>
                  <span className="menu-item-title menu-photo-item-title heading-3">
                    <SectionText name="title" text={data.title} cms={cms}/>
                  </span>
                  <br/>
                  <span className="menu-item-subtitle menu-photo-item-subtitle heading-4">
                    <SectionText name="subtitle" text={data.subtitle} cms={cms}/>
                  </span>
                </h3>
                <p>
                  <RichtextWithVariables
                    name="description"
                    richtext={data.description}
                    cms={cms}
                  />
                </p>

              </div>
              </LinkWrapper>
            </ItemWrapper>
          </div>
          <div className="menu-item-break"></div>
        </>
      )
    },
    template: itemTemplates.menuphoto,
  },
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      cms={props.cms}
      index={props.index}
      focusRing={{offset: -9, borderRadius: 4}}
      insetControls={false}
    >
      {props.children}
    </LucidBlocksControls>
  )
}

