import Helpers from '@/src/utils/shared/helpers'

export interface RSSItem {
    guid: string
    title: string
    link: string
    author?: string
    pubDate: Date
    description: string
}

async function parseXML(text: string): Promise<Document> {
    const parser = new DOMParser()

    let doc: Document
    try {
        doc = parser.parseFromString(text, 'text/xml')
    } catch (error) {
        throw new Error('Failed to parse XML from response text')
    }

    if (!doc || doc.querySelector('parsererror')) {
        throw new Error('Failed to parse XML, the document might be malformed.')
    }

    return doc
}

function parseRSSItem(item: Element): RSSItem {
    const getTextContent = (tagName: string): string => {
        const element = item.querySelector(tagName)
        return element ? Helpers.convert.decodeHTMLEntities(element.textContent ?? '') : ''
    }

    const guid = getTextContent('guid')
    const title = getTextContent('title')
    const link = getTextContent('link')
    const author = getTextContent('author')
    const pubDateStr = getTextContent('pubDate')
    const description = getTextContent('description')

    const pubDate = new Date(pubDateStr)
    if (isNaN(pubDate.getTime())) {
        throw new Error(`Invalid date: ${pubDateStr}`)
    }

    return {guid, title, link, author, pubDate, description}
}

export default async function RSSReader(feedUrl: string): Promise<RSSItem[]> {
    if (!feedUrl) return []

    let response: Response
    try {
        response = await fetch('/api/rss-proxy', {
            method: 'POST',
            body: JSON.stringify({feedUrl}),
        })
    } catch (error: any) {
        return []
    }

    if (!response.ok) {
        return []
    }

    const text = await response.text()
    try {
        const doc = await parseXML(text)
        return Array.from(doc.querySelectorAll('item')).map(parseRSSItem)
    } catch (error) {
        console.error(error)
        return []
    }
}
