import {LucidBlocksControls} from '../shared/LucidInlineGroups'
import {getBlogBlocks} from './shared'

export const BLOG_BLOCKS = getBlogBlocks(BlogItems)

function BlogItems({index, blog_posts, cms}) {
  if (!blog_posts || blog_posts.length === 0) {
    if (!cms) return null

    return (
      <ItemWrapper index={index} cms={cms} className="column">
        <div className="blog-post">
          <div className="paragraph">No blog posts selected. Click the edit icon to add some.</div>
        </div>
      </ItemWrapper>
    )
  }

  return (
    <ItemWrapper index={index} cms={cms}>
      {blog_posts?.map((post, index) => {
        return (
          <a key={index} href={post.link} className="blog-post">
            <span className="blog-post-date paragraph">{post.date}</span>
            <span className="blog-post-title heading-4">{post.title}</span>
          </a>
        )
      })}
    </ItemWrapper>
  )
}

function ItemWrapper(props) {
  return (
    <LucidBlocksControls
      index={props.index}
      cms={props.cms}
      focusRing={{offset: 16, borderRadius: 4}}
      insetControls={false}
      className="column"
    >
      {props.children}
    </LucidBlocksControls>
  )
}
