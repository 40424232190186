import {RichtextWithVariables} from '../shared/SectionsWithVariables'
import SectionText from '../shared/SectionText'
import Section from '../shared/Section'
import SectionButton from '../shared/SectionButton'
import React from 'react'
import {styleCore} from './stylecore'
import LucidInlineBlocks from '../shared/LucidInlineBlocks'
import {BLOG_BLOCKS} from './config'

export default function BlogCard(props) {
  const {
    title,
    subtitle,
    intro,
    outro,
    button,
    className,
    css_overrides,
    color_scheme_id_override,
    page_section_id,
    section_background,
    meta,
    cms
  } = props

  return (
    <Section
      cms={cms}
      css_overrides={css_overrides}
      color_scheme_id_override={color_scheme_id_override}
      meta={meta}
      page_section_id={page_section_id}
      className={className + ' section blogcard-section stack full-width menu-section'}
      name="blogcard"
      section_background={section_background}
    >
      <div className="section-container">
        <div className="section-content">
          <div className="section-heading">
            <h1>
              <div className="title heading-1">
                <SectionText name='title' text={title} cms={cms} />
              </div>
              <div className="subtitle heading-2">
                <SectionText name='subtitle' text={subtitle} cms={cms} />
              </div>
            </h1>
          </div>
          <div className="section-description">
            <div className="row">
              <div className="column">
                <div className="section-richtext">
                  <RichtextWithVariables
                    name='intro'
                    richtext={intro}
                    cms={cms}
                  />
                </div>
              </div>
            </div>
          </div>
          <LucidInlineBlocks 
            itemProps={props} 
            name='blog_posts' 
            className='row menus blog-card-items item-count-3'
            blocks={BLOG_BLOCKS} 
            min={1} 
            max={1} 
          />
          <div className="section-outro">
            <div className="row">
              <div className="column section-richtext paragraph">
                <RichtextWithVariables
                  name='outro'
                  richtext={outro}
                  cms={cms}
                />
              </div>
            </div>
          </div>
          {button?.url && button?.label && (
            <div className="button-container">
              <SectionButton
                name='button'
                href={button?.url}
                label={button?.label}
                cms={cms}
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

BlogCard.StyleCore = styleCore
